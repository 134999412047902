import React from 'react';
import Layout from '../components/Layout';

export default function Dashboard() {

    return (
        <Layout allowAnonymous={false}>
            Tasks
        </Layout>
    )
}